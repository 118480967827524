<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <template v-if="userProfileComplete===false">
      <div class="canna_headerQuestionarioIncomplete noselect spacerRowTop24">
        <div class="containerInfo">
          <div class="col_A">
            <div class="title">
              Hola {{current_profile.alias}}, ¡estás haciendo un gran trabajo!
            </div>
            <div class="description" 
              :class="{'spacerRowTop16': (is_Mobile || $q.screen.lt.md) ? true : false } "
            >
              ¡Termina de completar tu información personal para que podamos encontrar una gran oportunidad laboral para ti!
            </div>
          </div>
          <div class="col_B" 
            :class="{'spacerRowTop24': (is_Mobile || $q.screen.lt.md) ? true : false } "
          > 
            <q-btn color="cannaBtnFormAlt" icon-right="r_arrow_forward" label="Continuar" no-caps unelevated
              :style="(is_Mobile || $q.screen.lt.md) ? 'width: 100% !important;' : '' "
              @click.stop="rvw_gotoLastQPage"
            />
          </div>
        </div>
      </div>
    </template>

    <div class="ppi_pz_header noselect spacerRowTop24">
      <div class="pageZone_title">
        Información personal
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        Revisa tu información, si deseas realizar algún cambio puedes editar cualquier dato.
      </div>
    </div>

    
    <div>
      <div id="contenedor review" class="review_contents spacerRowTop48">
        <div id="review_zona_1">

          <div id="review_header" class="canna_reviewHeader">
            <div class="title_name">
              {{getProfileData.nombre}} {{getProfileData.paterno}} {{ (getProfileData.sin_materno===1) ? '' : getProfileData.materno }}
            </div>
            <div>
              <div class="review_link action_item" @click.stop="rvw_goQuestionarioScreen(0)">Editar</div>
            </div>
          </div>

          <div class="spacerRowTop48" style="display: flex;justify-content: flex-start;">
            <div>
              <template v-if="(userHasExp_Photo===false)">
                <img src="images/icono-persona.png" style="border-radius: 60px;width: 120px;height: 120px;">
              </template>
              <template v-else>
                <img :src="photo_getImagUrl()" style="border-radius: 60px;width: 120px;height: 120px; border: 1px solid #DCDBE1;">
              </template>
            </div>
          </div>

          <template v-if="current_profile_status>= zstatUser.PUESTOS"> <!-- 14 -->
            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Puestos de trabajo</div>
              <div class="data_section_contents">
                <template v-for="(puesto, index) in rvData_puestos">
                  <div class="data_rowContents" :key="'pstoItem_' + index">
                    {{puesto.categoria}} / {{puesto._embedded.catalogo.nombre}} 
                    {{ 
                      (puesto.categoriaId===7 && puesto.catalogo_id===58) ? puesto.otro : 
                        (puesto.categoriaId===6 && puesto.catalogo_id===37) ? puesto.otro: ''
                    }}
                  </div>
                </template>
              </div>
            </div>
          </template>

          <template v-if="current_profile_status>= zstatUser.SUELDO "> <!-- 15 -->
            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Sueldo mensual deseado</div>
              <div class="data_section_contents">
                <template v-for="(sueldo, index) in rvData_sueldos">
                  <div class="data_rowContents" :key="'sueldo_item_' + index">
                    {{sueldo._embedded.catalogo.nombre}}
                  </div>
                </template>
              </div>
            </div>
          </template>

          <template v-if="current_profile_status>= zstatUser.JORNADA">  <!-- 16 -->

            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Jornada laboral deseada</div>
              <div class="data_section_contents">
                <template v-for="(jornada, index) in rvData_jornadas">
                  <div class="data_rowContents" :key="'jornada_item_' + index">
                    {{jornada._embedded.catalogo.nombre}}
                  </div>
                </template>
              </div>
            </div>

            <div class="data_section spacerRowTop48 dataSection_separator">
              <div class="dataSection_title">Horario laboral deseado</div>
              <div class="data_section_contents">
                <template v-for="(horario, index) in rvData_horarios">
                  <div class="data_rowContents" :key="'horario_item_' + index">
                    {{horario._embedded.catalogo.nombre}}
                  </div>
                </template>
              </div>
            </div>

          </template>

        </div>

        <!-- Direccion, telefonos recados, pais, licencia transporte -->
        <div id="review_zona_2"> 

          <template 
            v-if="
              current_profile_status>=zstatUser.DIRECCION &&
               (getProfileData.telefono_recados !== null && getProfileData.telefono_recados!=='' && getProfileData.telefono_recados!==undefined) 
            "
          > <!-- 17 -->
            <div id="review_header" class="canna_reviewHeader">
              <div class="data_section spacerRowTop48" style="flex:1; margin-top: 0px !important;">
                <div class="dataSection_title">Dirección</div>
                <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{ (getProfileData.calle + ' ' +  getProfileData.numero_exterior).trim() }}{{ (getProfileData.numero_interior === '' || getProfileData.numero_interior === null) ? '' : ', ' + getProfileData.numero_interior }}{{ ', ' +  getProfileData.colonia }}{{ ', ' + getProfileData.alcaldia_municipio }}<br>
                    {{ getProfileData.codigo_postal }}{{ (getProfileData.ciudad === '' || getProfileData.ciudad === null) ? ', -' : ', ' + getProfileData.ciudad }}{{ (getProfileData.entidad_federativa === '' || getProfileData.entidad_federativa === null) ? '' : ', ' + getProfileData.entidad_federativa }}<br>
                    {{ (getProfileData.pais === '' || getProfileData.pais === null) ? 'México' : '' + getProfileData.pais }}
                  </div>
                </div>
              </div>
              <div>
                <div class="review_link action_item" @click.stop="rvw_goQuestionarioScreen(1)">Editar</div>
              </div>

            </div>

            <div class="data_section spacerRowTop48" v-if="(getProfileData.telefono_recados.length==13)">
              <div class="dataSection_title">Teléfono de recados</div>
              <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{getPhoneDataMasked(1, getProfileData.telefono_recados)}}
                  </div>
              </div>
            </div>

            <div class="data_section spacerRowTop48" v-if="(getProfileData.telefono_casa!==null && getProfileData.telefono_casa!=='')">
              <div class="dataSection_title">Teléfono de casa</div>
              <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{getPhoneDataMasked(1, getProfileData.telefono_casa)}}
                  </div>
              </div>
            </div>
          </template>

          <template v-if="current_profile_status>= zstatUser.PAIS "> <!-- 18 -->
            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">País de nacimiento</div>
              <div class="data_section_contents">
                <template v-for="(pais, index) in rvData_pais">
                  <div class="data_rowContents" :key="'pais_item_' + index">
                    {{pais._embedded.catalogo.descripcion}}
                  </div>
                </template>
              </div>
            </div>
          </template>

          <template v-if="current_profile_status>= zstatUser.LICENCIA "> <!-- 19 -->
            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Licencia de manejo</div>
              <div class="data_section_contents">
                  <div class="data_rowContents" v-html="licenciaTextos.licenciaMain"></div>
              </div>
            </div>
          </template>

          <template v-if="current_profile_status>= zstatUser.TIENES_AUTO"> <!-- 20 -->

            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Transporte personal</div>
              <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{licenciaTextos.hasAuto }}
                  </div>
              </div>
            </div>

            <div class="data_section spacerRowTop48 dataSection_separator">
              <div class="dataSection_title">Tarjeta de circulación</div>
              <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{licenciaTextos.hasCirculacion}}
                  </div>
              </div>
            </div>
          </template>


        </div>

        <!-- Sexo, Medidas -->
        <template v-if="current_profile_status>= zstatUser.MEDIDAS "> <!-- 21 -->
          <div id="review_zona_3"> 

            <div id="review_header" class="canna_reviewHeader">
              <div class="data_section spacerRowTop48" style="flex:1; margin-top: 0px !important;">
                <div class="dataSection_title">Sexo</div>
                <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{ (getProfileData.sexo ==='M') ? 'Masculino' : 'Femenino' }}
                  </div>
                </div>
              </div>
              <div>
                <div class="review_link action_item" @click.stop="rvw_goQuestionarioScreen(2)">Editar</div>
              </div>
            </div>

            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Estatura</div>
              <div class="data_section_contents">
                <template v-for="(altura, index) in rvData_Estatura">
                  <div class="data_rowContents" :key="'altura_item_' + index">
                    {{altura._embedded.catalogo.descripcion}}
                  </div>
                </template>
              </div>
            </div>

            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Peso</div>
              <div class="data_section_contents">
                <template v-for="(pesox, index) in rvData_Peso">
                  <div class="data_rowContents" :key="'pesox_item_' + index">
                    {{pesox._embedded.catalogo.descripcion}}
                  </div>
                </template>
              </div>
            </div>

            <div class="data_section spacerRowTop48 dataSection_separator">
              <div class="dataSection_title">Talla</div>
              <div class="data_section_contents">
                <template v-for="(tallax, index) in rvData_talla">
                  <div class="data_rowContents" :key="'tallax_item_' + index">
                    {{tallax._embedded.catalogo.nombre}} <template v-if="rvdata_TallaUniversal!==''"> / {{rvdata_TallaUniversal}}</template>
                  </div>
                </template>
              </div>
            </div>

          </div>
        </template>

        <!-- Escolaridad, EXPERIENCIA , EXPERIENCIA LABORAL , curriculum -->
        <div id="review_zona_4"> 

          <template v-if="current_profile_status>= zstatUser.ESCOLARIDAD ">  <!-- 22 -->

            <div id="review_header" class="canna_reviewHeader">
              <div class="data_section spacerRowTop48" style="flex:1; margin-top: 0px !important;">
                <div class="dataSection_title">Último grado de estudios</div>
                <div class="data_section_contents">
                  <div class="data_rowContents">
                    <template v-for="(gradox, index) in rvData_EscuelaGrado">
                      <div class="data_rowContents" :key="'gradox_item_' + index">
                        {{gradox._embedded.catalogo.nombre}}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div>
                <div class="review_link action_item" @click.stop="rvw_goQuestionarioScreen(3)">Editar</div>
              </div>
            </div>

            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Título o certificado</div>
              <div class="data_section_contents">
                <template v-for="(tituloEscuelax, index) in rvData_EscuelaTitulo">
                  <div class="data_rowContents" :key="'esctitulo_item_' + index">
                    {{tituloEscuelax._embedded.catalogo.nombre}}
                  </div>
                </template>
              </div>
            </div>

            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Escuela</div>
              <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{rvData_EscuelaNombre}}
                  </div>
              </div>
            </div>

            <div class="data_section spacerRowTop48" v-if="(rvData_EscuelaCarrera!=='')">
              <div class="dataSection_title">Carrera</div>
              <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{rvData_EscuelaCarrera}}
                  </div>
              </div>
            </div>

            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Idiomas hablados</div>
              <div class="data_section_contents" style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <template v-for="(idioma, index) in rvData_Idiomas">
                  <div class="data_rowContents" :key="'idioma_item_' + index">
                    {{ (index>0) ? ',' : '' }} {{idioma._embedded.catalogo.nombre}} {{(idioma.catalogo_id===8) ? idioma.otro : ''}}
                  </div>
                </template>
              </div>
            </div>

          </template>

          <template v-if="current_profile_status>= zstatUser.CANALES_EXPERIENCIA "> <!-- 23 -->

            <!--tiene experiencia -->
            <div class="data_section spacerRowTop48">
              <div class="dataSection_title">Experiencia laboral</div>
              <div class="data_section_contents">
                  <div class="data_rowContents">
                    {{experienciaLab.tieneMain}}
                  </div>
              </div>
            </div>

            <!-- tiempo de experiencia -->
            <div class="data_section spacerRowTop48" v-if="(experienciaLab.tiene===true)">
              <div class="dataSection_title">Años de experiencia laboral</div>
              <div class="data_section_contents">
                  <div class="data_rowContents">
                    <template v-for="(tiempoItem , index) in rvData_TiempoExperiencia">
                      <div class="data_rowContents" :key="'esctitulo_item_' + index">
                        {{tiempoItem._embedded.catalogo.nombre}}
                      </div>
                    </template>
                  </div>
              </div>
            </div>

            <!-- Canales de experiencia -->
            <div class="data_section spacerRowTop48"  v-if="(experienciaLab.tiene===true)">
              <div class="dataSection_title">Canales de experiencia</div>
              <div class="data_section_contents" style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <template v-for="(canalg, index) in rvData_CanalesGenerales">
                  <div class="data_rowContents" :key="'canalg_item_' + index">
                    {{ (index>0) ? ',' : '' }} {{canalg._embedded.catalogo.nombre}} {{(canalg.catalogo_id===116) ? ' (' + canalg.otro + ')' : ''}}
                  </div>
                </template>

                <template v-for="(canalesp, index) in rvData_CanalesEspeciales">
                  <div class="data_rowContents" :key="'canalesp_item_' + index">
                    {{ (index>0) ? ',' : (index==0 && rvData_CanalesGenerales.length>0) ? ',' : '' }} 
                    {{canalesp._embedded.catalogo.nombre}} {{(canalesp.catalogo_id===126) ? ' (' + canalesp.otro + ')'  : ''}}
                  </div>
                </template>
              </div>
            </div>

          </template>

          <!-- ZONA de ULTIMOS EMPLEOS -->
          <template v-if="current_profile_status>= zstatUser.LABORAL_EXPERIENCIA "> <!-- 24 (nuevo dev-sprint12) -->
            <!-- Canales de experiencia -->
            <div class="data_section spacerRowTop48"  v-if="(experienciaLab.tiene===true && experienciaLab.empleos.length>0)">
              <div class="dataSection_title">Últimos empleos</div>
              <div class="data_section_contents" style="display: flex;flex-direction: column;flex-wrap: wrap;">

                <template v-for="(empleox, index) in experienciaLab.empleos">
                  <div class="data_rowContents" :key="'empleo_item_' + index" v-html="empleox"></div>
                </template>

              </div>
            </div>
          
          </template>


          <template v-if="current_profile_status>= zstatUser.CURRICULUM "> <!-- 24 || ahora 25  -->
            <div class="data_section spacerRowTop48"
              :class="{ 'dataSection_separator': (current_profile_status>= zstatUser.REDES_SOCIALES )?true:false  }" 
            > <!-- dataSection_separator  25 || ahora 26  -->

              <div class="dataSection_title">Currículum Vítae</div>
              <div class="data_section_contents">
                <template v-if="docsDB_Records.length>0">
                  <div class="data_rowContents">
                    Un archivo cargado: <br>
                    <span class="review_link">{{ docsDB_Records[0].nombre_original }}</span>
                  </div>
                </template>
              </div>
            </div>
          </template>


        </div>

        <template v-if="current_profile_status>= zstatUser.REDES_SOCIALES "> <!-- 25 || ahora 26  -->
          <div id="review_zona_5"> 

              <div id="review_header" class="canna_reviewHeader">
                <div class="data_section spacerRowTop48" style="flex:1; margin-top: 0px !important;">
                  <div class="dataSection_title">¿Cuál es tu estado civil?</div>
                  <div class="data_section_contents">
                    <div class="data_rowContents">
                      <template v-if="rvData_EstadoCivil.length===1">
                        <!-- catalogo id = 9  Estado Civil -->
                        <template>
                          {{ rvData_EstadoCivil[0].nombreCatalogo }}
                        </template>
                      </template>
                      <template v-else><!-- en caso de posible error -->
                        Sin información
                      </template>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="review_link action_item" @click.stop="rvw_goQuestionarioScreen(4)">Editar</div>
                </div>
              </div>

              <div class="data_section spacerRowTop48">
                <div class="dataSection_title">¿Tienes crédito Infonavit vigente?</div>
                <div class="data_section_contents">
                  <div class="data_rowContents">
                    <template v-if="rvData_infonavit.length===1">
                      <!-- catalogo id = 78  ¿Tienes crédito Infonavit vigente?  965: SI  966:NO -->
                      <template>
                        {{ (rvData_infonavit[0].catalogo_id===965) ? 'Si, tengo un crédito Infonavit vigente': 'No, no tengo un crédito Infonavit vigente' }}
                      </template>
                    </template>
                    <template v-else><!-- en caso de posible error -->
                      No, no tengo un crédito Infonavit vigente
                    </template>
                  </div>
                </div>
              </div>

              <div class="data_section spacerRowTop48">
                <div class="dataSection_title">¿Anteriormente has trabajado con nosotros?</div>
                <div class="data_section_contents">
                  <div class="data_rowContents">
                    <template v-if="rvData_TrabajadoNosotros.length===1">
                      <!-- catalogo id = 62  Ha trabajado con nosotros  756: SI  757:NO -->
                      <template>
                        {{ (rvData_TrabajadoNosotros[0].catalogo_id===756) ? 'Sí, si he trabajado con ustedes': 'No, no he trabajado con ustedes' }}
                      </template>
                    </template>
                    <template v-else><!-- en caso de posible error -->
                      No, no he trabajado con ustedes *
                    </template>
                  </div>
                </div>
              </div>

              <div class="data_section spacerRowTop48">
                <div class="dataSection_title">¿Cómo te enteraste de nosotros?</div>
                <div class="data_section_contents" style="display: flex;flex-direction: row;flex-wrap: wrap;">
                  <template v-for="(redItem, index) in rvData_redesSociales">
                    <div class="data_rowContents" :key="'redSocialxItem_' + index">
                      {{ (index>0) ? ',' : '' }} {{redItem._embedded.catalogo.nombre}} {{(redItem.catalogo_id===21) ? redItem.otro : ''}}
                    </div>
                  </template>
                </div>
              </div>

          </div>
        </template>

      </div>
    </div>

  </div>
</template>

<script>
import { requestPack } from '../../../../boot/axios' // APP code 
import logOut_Mixin from '../../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import { helperCatsTallas_Mixin } from './../../../../mixins/helperCatsMix.js'

import { estatusUsuario } from 'boot/canna_settings.js'

// const cannaEstatus = { 
//   usuario: {
// 		DELETED : 0, // 
//     INACTIVE : 9, // 
//     ACTIVE : 10, // Registro de un nuevo usuario desde frontend/api
//     DATOS_PERSONALES : 11, // 
//     DATOS_LEGALES : 12, // 
//     FOTO : 13, // 
//     PUESTOS : 14, // 
//     SUELDO : 15, // 
//     JORNADA : 16, // 
//     DIRECCION : 17, // 
//     PAIS : 18, // 
//     LICENCIA : 19, // 
//     TIENES_AUTO : 20, // 
//     MEDIDAS : 21, // 
//     ESCOLARIDAD : 22, // 
//     CANALES_EXPERIENCIA : 23, // 
    
//     LABORAL_EXPERIENCIA : 24, // dev-sprint 12  (nueva pantalla) 
    
//     CURRICULUM : 25, // --->24 -- 25 
//     REDES_SOCIALES : 26, // --->25 -- 26 
//     PREVIEW : 27, //	--->26 --- 27 
//     COMPLETO : 28, //		--->27 --- 28 // FRONT (formulario e infoemacion completada al 100%) 
//     CONTRATADO : 29, //	--->28 --- 29 
//     NO_DESEADO : 30 // --->29 --- 30 
//   },


const tplSecciones =  [
  {id: 0, name: 'Foto', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path:'/dashboard/personal', estatus: estatusUsuario.FOTO },
  {id: 1, name: 'Puestos de trabajo', categorias:[6,7], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/trabajo-b', estatus: estatusUsuario.PUESTOS },
    // catalogo id = 6  Puestos operativos
    // catalogo id = 7  Puestos administrativos
  {id: 2, name: 'Sueldo mensual deseado', categorias:[8], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/trabajo-c', estatus: estatusUsuario.SUELDO },
    // catalogo id = 8  Rango de sueldos
  {id: 3, name: 'Jornada laboral deseada', categorias:[25], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/trabajo-d', estatus: estatusUsuario.JORNADA },
    // catalogo id = 25 Jornada laboral
  {id: 4, name: 'Horario laboral deseado', categorias:[11], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/trabajo-d', estatus: estatusUsuario.JORNADA },
    // catalogo id = 11 Horario

  {id: 5, name: 'Dirección', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/direccion-a', estatus: estatusUsuario.DIRECCION },
  {id: 6, name: 'Teléfono de recados', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/direccion-a', estatus:  estatusUsuario.DIRECCION },
  {id: 7, name: 'Teléfono de casa', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/direccion-a', estatus: estatusUsuario.DIRECCION },

  {id: 8, name: 'País de nacimiento', categorias:[27], dataHtml:'', totalRecs: 0, complete: false, path:'/dashboard/personal/direccion-b', estatus: estatusUsuario.PAIS },
    // catalogo id = 27 País de nacimiento
  {id: 9, name: 'Licencia de manejo', categorias:[12,23,58], dataHtml:'', totalRecs: 0, complete: false, path:'/dashboard/personal/direccion-c', estatus: estatusUsuario.LICENCIA },
    // catalogo id = 12 Tiene Licencia (si:95 no:96)
    // catalogo id = 23 Tipo Licencia
    // catalogo id = 58 Tiene Licencia Permanente (si:754 no:755)  NOTA en  NO guardar otro : fehca de vigencia 
  {id: 10, name: 'Transporte personal', categorias:[13], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/direccion-d', estatus: estatusUsuario.TIENES_AUTO },
  {id: 11, name: 'Tarjeta de circulación', categorias:[54], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/direccion-d', estatus: estatusUsuario.TIENES_AUTO },
    // catalogo id = 13 Tiene auto (si:97 no:98)
    // catalogo id = 54 tarjeta circulacion (si:731 no:732)
  {id: 12, name: 'Sexo', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/uniformes-a', estatus: estatusUsuario.MEDIDAS }, // catalogo id = 1 Genero  (F:1   M:2)
  {id: 13, name: 'Estatura', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/uniformes-a', estatus: estatusUsuario.MEDIDAS }, // catalogo id = 52 Estatura 
  {id: 14, name: 'Peso', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/uniformes-a', estatus: estatusUsuario.MEDIDAS }, // catalogo id = 53 Peso
  {id: 15, name: 'Talla', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/uniformes-a', estatus: estatusUsuario.MEDIDAS }, 
    // catalogo id = 19 Tallas de hombre 
    // catalogo id = 20 Tallas de mujer
    // catalogo id = 63 Tallas / Medidas Universales 758 a 763
  {id: 16, name: 'Último grado de estudios', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-a', estatus: estatusUsuario.ESCOLARIDAD }, 
    // catalogo id = 21 Nivel académico (grados de estudio)
  {id: 17, name: 'Título o certificado', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-a', estatus: estatusUsuario.ESCOLARIDAD },
    // catalogo id = 22 Comprobante de estudios (títulos)
  {id: 18, name: 'Escuela', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-a', estatus: estatusUsuario.ESCOLARIDAD },
    // catalogo id = 59 Datos escolaridad   id:748
  {id: 19, name: 'Carrera', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-a', estatus: estatusUsuario.ESCOLARIDAD },
    // catalogo id = 59 Datos escolaridad   id:749
  {id: 20, name: 'Idiomas hablados', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-a', estatus: estatusUsuario.ESCOLARIDAD },
    // catalogo id = 2 Idiomas (otro : 8)
  {id: 21, name: 'tiene experiencia', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-b', estatus: estatusUsuario.CANALES_EXPERIENCIA },
  {id: 22, name: 'tiempo de experiencia', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-b', estatus: estatusUsuario.CANALES_EXPERIENCIA },
  {id: 23, name: 'Canales de experiencia', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-b', estatus: estatusUsuario.CANALES_EXPERIENCIA }, 
    // Experiencia 
    // catalogo id = 14  Tiene experiencia  99: SI  100:NO 
    // catalogo id = 55  Buscador de tiempo de experiencia laboral
    // catalogo id = 17  Canal de experiencia generales
    // catalogo id = 18  Canal de experiencia especializados
  // ULTIMOS EMPLEOS ()
  {id: 24, name: 'Expeciencia laboral', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-c', estatus: estatusUsuario.LABORAL_EXPERIENCIA }, // dv-sp12 cambio  nuevo 

  {id: 25 , idOld: 24, name: 'Currículum Vítae', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/aptitudes-d', estatus: estatusUsuario.CURRICULUM },  //estatus: 24 dv-sp12 cambio 
    // Revisar Documentos tipo / catalogoid 64  TIpo doc  == 772  antes era 764
  {id: 26 , idOld: 25, name: 'Sociales', categorias:[], dataHtml:'', totalRecs: 0, complete: false, path: '/dashboard/personal/mediossociales', estatus: estatusUsuario.REDES_SOCIALES } // estatus: 25 dv-sp12 cambio 
    // catalogo id = 62  Ha trabajado con nosotros  756: SI  757:NO 
    // catalogo id = 4 Redes sociales
    // catalogo id = 9 Estado civil
]

export default {
  name: 'psi_screen_review',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin, 
    helperCatsTallas_Mixin
  ],
  components:{

  },
  data () {
    return {
      version: '1.0.1',
      debugEls: false,
      z_nodataFlag:false,
      zinitData: false, 
      zinitScreen: false,
      zloadingData: false,
      processingData: false, 
      //--------
      userProfileComplete:false,
      userHasExp_Photo: false,
      infoPersona: {},
      info_Sections:{ zona_a: [], zona_b: [], zona_c: [], zona_d: [] },
      profileRecords: [],
      docsDB_Records:[], // PAra el curriculum 
      experienciaLab: { 
        tiene: false, 
        tieneMain: '',
        empleos:[]
      },
      licenciaTextos: { licenciaMain:'', hasAuto:'', hasCirculacion:'' },
      seccionesReview: JSON.parse(JSON.stringify(tplSecciones)), //Aqui se concentra todo
      zz_otros: null
    }
  },
  computed: {
    current_profile (){
      return this.getProfileData
    },
    current_profile_status (){
      return parseInt(this.getProfileData.status)
    },
    cat_Universales () { // catalogo id = 63 Tallas / Medidas Universales 758 a 763
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(63) : this.getHelperCat(63)
    },
    rvData_puestos () {
      var myArr = this.profileRecords.filter(item=> item.categoriaId===6 || item.categoriaId===7)
      return myArr
    },
    rvData_sueldos () { 
      var myArr = this.profileRecords.filter(item=> item.categoriaId===8)
      return myArr
    }, 
    rvData_jornadas () { 
      var myArr = this.profileRecords.filter(item=> item.categoriaId===25)
      return myArr
    },
    rvData_horarios () { 
      var myArr = this.profileRecords.filter(item=> item.categoriaId===11)
      return myArr
    },
    rvData_pais () { 
      var myArr = this.profileRecords.filter(item=> item.categoriaId===27)
      return myArr
    },
    rvData_Estatura () {
      var myArr = this.profileRecords.filter(item=> item.categoriaId===52)
      return myArr
    },
    rvData_Peso () {
      var myArr = this.profileRecords.filter(item=> item.categoriaId===53)
      return myArr
    },
    rvData_talla () { 
      var myArr = (this.getProfileData.sexo ==='M') ? this.profileRecords.filter(item=> item.categoriaId===19) : this.profileRecords.filter(item=> item.categoriaId===20)
      return myArr
    },
    rvdata_TallaUniversal () { 
      var indexUniversal = null
      var myArr = (this.getProfileData.sexo ==='M') ? this.profileRecords.filter(item=> item.categoriaId===19) : this.profileRecords.filter(item=> item.categoriaId===20)
      if (myArr.length<=0){ 
        return ''
      } else {
        if (this.getProfileData.sexo ==='M'){ 
          indexUniversal = this.list_refTallas.findIndex( item => item.m_val === myArr[0].catalogo_id ) 
        } else { 
          indexUniversal = this.list_refTallas.findIndex( item => item.f_val === myArr[0].catalogo_id ) 
        }
        if (indexUniversal>=0){
          return this.list_refTallas[indexUniversal].u_label
        } else { 
          return ''
        }
      }
    },
    rvData_EscuelaGrado () { 
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===21) 
      return myArr
    },
    rvData_EscuelaTitulo () { 
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===22) 
      return myArr
    },
    rvData_EscuelaNombre () { 
      var myArr =  this.profileRecords.filter(item=> item.catalogo_id===748) 
      if (myArr.length>0){ 
        return myArr[0].otro
      } else {
        return ''
      }
    },
    rvData_EscuelaCarrera () { 
      var myArr =  this.profileRecords.filter(item=> item.catalogo_id===749) 
      if (myArr.length>0){ 
        return myArr[0].otro
      } else {
        return ''
      }
    },
    rvData_Idiomas () { 
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===2) 
      return myArr
    },
    rvData_CanalesGenerales () { 
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===17) 
      return myArr
    },
    rvData_TieneExperiencia () { // Nueva cat agregada  (si = 99 No: 100 )
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===14) 
      return myArr
    },
    rvData_TiempoExperiencia () { // Nueva cat agregada de 735 a 740
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===55)
      return myArr
    },
    rvData_CanalesEspeciales () { 
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===18) 
      return myArr
    },
    rvData_TrabajadoNosotros () { // (SI = 756 NO = 757)
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===62)
      return myArr
    },
    rvData_infonavit () { // (SI = 756 NO = 757)
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===78)
      return myArr
    },
    rvData_EstadoCivil () {
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===9)
      return myArr
    },
    rvData_redesSociales () {
      var myArr =  this.profileRecords.filter(item=> item.categoriaId===4)
      return myArr
    },


  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () {
      this.$cannaDebug('-- psiPage_review -- test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    rvw_gotoLastQPage(){
      var test = 11
       this.$cannaDebug('-- psiPage_review -- continua questionario')
      var newRuta = ''
      // Obteneenemos la ultima ruta dependiendo el estatus de la persona 
      if (this.current_profile_status>= this.zstatUser.DATOS_LEGALES || this.current_profile_status<= this.zstatUser.CURRICULUM ) { //  12 (datos legales) -- 25 (curric)
        newRuta = ''
        var infoSeccs = this.seccionesReview.filter(itemSecc => itemSecc.complete === false)
        if (infoSeccs.length>0){ 
          newRuta =  infoSeccs[0].path
        }
      } else if (this.current_profile_status>= this.zstatUser.CURRICULUM ) { // 24 
        newRuta = ''
        var infoSeccs = this.seccionesReview.filter(itemSecc => itemSecc.complete === false)
        if (infoSeccs.length>0){ 
          newRuta =  infoSeccs[0].path
        }
      } else {
        var indexUse =  this.seccionesReview.findIndex(itemSecc => itemSecc.complete === false)
        newRuta = (indexUse<0) ? '' : this.seccionesReview[indexUse].path
      }
      if (newRuta!==''){ 
        // alert('IR a ruta ::' + newRuta )
        test=22
      } else {
        return
      }
      if (newRuta!==null && newRuta !==undefined && newRuta!=='' && test==22){
        this.$router.push(newRuta)
      }
    },
    rvw_goQuestionarioScreen ( tipo ) {
      this.$cannaDebug('-- psiPage_review -- beforeCreate')
      var pageOptions = [
        '/dashboard/personal/',
        '/dashboard/personal/direccion-a',
        '/dashboard/personal/uniformes-a',
        '/dashboard/personal/aptitudes-a',
        '/dashboard/personal/mediossociales'
      ]
      var newRuta = pageOptions[tipo]
      if (newRuta!==null && newRuta !==undefined && newRuta!==''){
        this.$router.push(newRuta)
      }
    },
    photo_getImagUrl() {
      this.$cannaDebug('-- dashMainScreen -- photo _ get Imag Url')
      var url = ''
      if (this.current_profile._links['file-stream'].href.indexOf('?')>=0){
        url= '' + this.current_profile._links['file-stream'].href + '&accessToken=' + this.$store.state.myapp.appUserData.access_token
      } else {
        url= '' + this.current_profile._links['file-stream'].href + '?accessToken=' + this.$store.state.myapp.appUserData.access_token
      }
      return url
    },
    getPhoneDataMasked (tipoDato , valor ) {
      if (tipoDato === 1 && valor.length===13) {  // +XX (XX) XXXX XX90
        var myTel = valor
        var tel = myTel.split('')
        tel.splice(3, 0, ' (');
        tel.splice(6, 0, ') ');
        tel.splice(11, 0, ' ');
        var prueba = tel.join('')
        return '' + prueba
      } else { 
        return '----'
      }
    },
    textos_Licencias_InfoCreate () { 
      // licenciaTextos: {
      //   licenciaMain:'',
      //   hasAuto:'',
      //   hasCirculacion:''
      // }
      var licArr = this.profileRecords.filter(item=> item.categoriaId===12)
      if (licArr.length<=0) {
        this.licenciaTextos.licenciaMain = ''
      } else { 
        var txtlic = '' 
        var hasLicencia = (licArr.findIndex( item=> item.catalogo_id === 95) >=0 ) ? true : false 
        if (hasLicencia===true){ 
          // REvisar tipo y vigencia 
          txtlic+= 'Sí, tengo una licencia' 
          var tplicArr = this.profileRecords.filter(item=> item.categoriaId===23)
          if (tplicArr.length<=0){  // Casos raros 
            txtlic+= '<br>Tipo: Error en licencia' 
          } else { 
            txtlic+= '<br>Tipo: ' + tplicArr[0].nombreCatalogo
          }
          var tplicVigarr = this.profileRecords.filter(item=> item.categoriaId===58)
          if (tplicVigarr.length<=0){  // Casos raros 
            txtlic+= '<br>Vigencia: Error en vigencia' 
          } else { 
            if  ( tplicVigarr[0].catalogo_id === 754) { 
              txtlic+= '<br>Vigencia: Permanente'
            } else {
              var fechaVigencia = ''
              fechaVigencia = '' + tplicVigarr[0].otro  
              var fechaX = this.$moment(('' + tplicVigarr[0].otro) ,'YYYY-MM-DD')
              if (fechaX.isValid() !== true ){ 
                txtlic+= '<br>Vigencia: Error en fecha de vigencia (es inválida) '
              } else {
                txtlic+= '<br>Vigencia: ' + this.$moment(('' + tplicVigarr[0].otro) ,'YYYY-MM-DD').format('DD, MMMM, YYYY')
                //txtlic+= ( tplicVigarr[0].catalogo_id === 754) ? 'Permanente' : this.$moment(('' + tplicVigarr[0].otro) ,'YYYY-MM-DD').format('DD, MMMM, YYYY')
              }
            }
            
            // var fechaVigencia = '' 
            // fechaVigencia = '' + tplicVigarr[0].otro  
            // var fechaX = this.$moment(('' + tplicVigarr[0].otro) ,'YYYY-MM-DD')
            // if (fechaX.isValid() !== true ){ 
            //   txtlic+= '<br>Vigencia: Error en vigencia '
            // } else {
            //   txtlic+= '<br>Vigencia: '
            //   txtlic+= ( tplicVigarr[0].catalogo_id === 754) ? 'Permanente' : this.$moment(('' + tplicVigarr[0].otro) ,'YYYY-MM-DD').format('DD, MMMM, YYYY')
            // }

          }
        } else {
          txtlic = 'No, no tengo una licencia'
        }
        this.licenciaTextos.licenciaMain = txtlic
      }
      // ----------------------------
      // Tiene Auto 
      var licTArr = this.profileRecords.filter(item=> item.categoriaId===13)
      if (licTArr.length<=0) {
        this.licenciaTextos.hasAuto = ''
      } else { 
        // Tiene Circulacion 
        var txtTA = '' 
        txtTA+= (licTArr.findIndex(item=> item.catalogo_id === 97) >=0 ) ? 'Sí, tengo un automóvil/motocicleta propios' : 'No, no tengo un automóvil/motocicleta propios'
        this.licenciaTextos.hasAuto = '' + txtTA
      }
      // ----------------------------
      // Tiene T.Circulacion
      var licCArr = this.profileRecords.filter(item=> item.categoriaId===54)
      if (licCArr.length<=0) {
        this.licenciaTextos.hasCirculacion = 'No, no tengo tarjeta de circulación vigente'
      } else { 
        // Tiene Circulacion 
        var txtTC = '' 
        txtTC+= (licCArr.findIndex(item=> item.catalogo_id === 731) >=0 ) ? 'Sí, tengo tarjeta de circulación vigente' : 'No, no tengo tarjeta de circulación vigente'
        this.licenciaTextos.hasCirculacion = '' + txtTC
      }
    },
    textos_Experiencia_InfoCreate () { 
      // Establecemos los datos principales..!
      var licExpArr = this.profileRecords.filter(item=> item.categoriaId===14)
      if (licExpArr.length<=0) {
        this.experienciaLab.tiene = false
        this.experienciaLab.tieneMain = 'No, no tengo experiencia laboral'
      } else { // (si = 99 No: 100 )
        this.experienciaLab.tiene = (licExpArr.findIndex(item=> item.catalogo_id === 99) >=0 ) ? true : false 
        var txtTC = '' 
        txtTC+= (licExpArr.findIndex(item=> item.catalogo_id === 99) >=0 ) ? 'Sí, tengo experiencia laboral' : 'No, no tengo experiencia laboral'
        this.experienciaLab.tieneMain = '' + txtTC
      }
    },
    textos_ExperienciaLaboral_InfoCreate () { //dev-sprint12 
      //Establecemos datos principales 
      var empleosStrData = []
      if (this.current_profile._embedded.experienciaLaboral){
        if( this.current_profile._embedded.experienciaLaboral.length>0) { 

          var dataEmpleos = JSON.parse(JSON.stringify(this.current_profile._embedded.experienciaLaboral))
          //Se ordena por fecha descendente
          dataEmpleos.sort((a, b) => {
            const Aof = this.$moment(a.fecha_inicio , 'YYYY-MM-DD')
            const Bof = this.$moment(b.fecha_inicio , 'YYYY-MM-DD')
            let comparison = 0;
            comparison = Bof.isAfter(Aof) ? 1 : Bof.isBefore(Aof) ? -1 : 0 //comparison = Ao > Bo ? 1 : Ao < Bo ? -1 : 0;
            return comparison;
          })
          dataEmpleos.forEach( empx => {
            var txtEmpleo = ''
            var fechaIni = this.$moment(empx.fecha_inicio , 'YYYY-MM-DD').format('MMMM, YYYY')
            var fechaFin = (empx.actualmente_trabaja_ahi === 1) ?  ', y aún trabajo aquí.' : ' hasta <span style="text-transform: capitalize;">' + this.$moment(empx.fecha_fin, 'YYYY-MM-DD').format('MMMM, YYYY') + '</span>'
            txtEmpleo+= '' + empx.puesto + ' en ' + empx.nombre_empresa + '<br> Desde <span style="text-transform: capitalize;">' + fechaIni + '</span>' + fechaFin
            empleosStrData.push(txtEmpleo)
          })
          // [¿Qué puesto ocupaste?] en [Nombre de la empresa]
          // Desde [Mes inicio], [Año inicio] hasta [Fecha fin]

          // Diseñador editorial en Capital Media
          // Desde Julio, 2017 hasta Diciembre, 2017

          // Desde Diciembre, 2017 hasta Actualmente trabajo aquí
        }
      }
      this.experienciaLab.empleos = empleosStrData
    },
    async estatus_doCHeckFinalData() { 
      //Revisa la Info Obtenida y Actualiza el estatus del profile 
      this.$cannaDebug('-- psiPage_review -- estatus -- do CHeck FinalData')
      var indexFlag = null //
      // Foto  // estatus - 12-13
      if (this.userHasExp_Photo === true){ 
        this.seccionesReview[0].complete = true
      }
      //----------
      // Puestos //estatus - 14
      indexFlag = this.seccionesReview.findIndex( item => item.id===1)
      this.seccionesReview[indexFlag].complete = (this.current_profile_status>= this.zstatUser.PUESTOS ) ? ((this.rvData_puestos.length>=1) ? true : false ) : false 

      //----------
      // Sueldo // estatus - 15
      indexFlag = this.seccionesReview.findIndex( item => item.id===2)
      this.seccionesReview[indexFlag].complete = (this.current_profile_status>= this.zstatUser.SUELDO ) ? ((this.rvData_sueldos.length>=1) ? true : false) : false
      //----------
      // Jornada // estatus - 16
      indexFlag = this.seccionesReview.findIndex( item => item.id===3)
      this.seccionesReview[indexFlag].complete = (this.current_profile_status>= this.zstatUser.JORNADA ) ? ((this.rvData_jornadas.length>=1) ? true : false) : false 
      //----------
      // Horario // estatus - 16
      indexFlag = this.seccionesReview.findIndex( item => item.id===4)
      this.seccionesReview[indexFlag].complete = (this.current_profile_status>= this.zstatUser.JORNADA) ? ((this.rvData_horarios.length>=1) ? true : false) : false 
      //----------
      //Direccion  // estatus - 17
      var ValidDataDireccion = false
      if (this.current_profile_status>= this.zstatUser.DIRECCION ){
        if (
          (this.current_profile.codigo_postal == '' || this.current_profile.codigo_postal == null) ||
          (this.current_profile.entidad_federativa == '' || this.current_profile.entidad_federativa == null) ||
          (this.current_profile.alcaldia_municipio == '' || this.current_profile.alcaldia_municipio == null) ||
          (this.current_profile.ciudad == '' || this.current_profile.ciudad == null)
        ) { 
          ValidDataDireccion = false
        } else { 
          ValidDataDireccion = true 
        }
      }
      indexFlag = this.seccionesReview.findIndex( item => item.id===5)
      this.seccionesReview[indexFlag].complete = ValidDataDireccion
      //----------
      // Tel recados // estatus - 17
      var ValidDataTelReca = false
      if (this.current_profile_status>= this.zstatUser.DIRECCION ){
        if ((this.current_profile.telefono_recados == '' || this.current_profile.telefono_recados == null)) {
          ValidDataTelReca = false
        } else { 
          ValidDataTelReca = true 
        }
      }
      indexFlag = this.seccionesReview.findIndex( item => item.id===6)
      this.seccionesReview[indexFlag].complete = (ValidDataTelReca===true && ('' + this.current_profile.telefono_recados).length===13) ? true : false 
      //----------
      //Tel casa  // estatus - 17
      var ValidDataTelCasa = false
      if (this.current_profile_status>= this.zstatUser.DIRECCION ){
        if ((this.current_profile.telefono_casa == '' || this.current_profile.telefono_casa == null)) {
          ValidDataTelCasa = true
        } else {
          ValidDataTelCasa = true 
        }
      }
      indexFlag = this.seccionesReview.findIndex( item => item.id===7)
      this.seccionesReview[indexFlag].complete = ValidDataTelCasa
      //----------
      //Pais // estatus - 18
      indexFlag = this.seccionesReview.findIndex( item => item.id===8)
      this.seccionesReview[indexFlag].complete =  (this.current_profile_status>= this.zstatUser.PAIS ) ? ((this.rvData_pais.length>=1) ? true : false) : false 
      //----------
      //Licencia // estatus - 19
      var completeLicencia = false 
      indexFlag = this.seccionesReview.findIndex( item => item.id===9)
      if (this.current_profile_status>= this.zstatUser.LICENCIA ){
        var myArrLic = this.profileRecords.filter(item=> item.categoriaId===12 || item.categoriaId===23 || item.categoriaId===58)
        var indexOfNOLIC = this.profileRecords.findIndex( item=> item.catalogo_id === 96)
        if (indexOfNOLIC>=0){
          this.seccionesReview[indexFlag].complete = (myArrLic.length>=1) ? true : false
        } else { 
          this.seccionesReview[indexFlag].complete = (myArrLic.length>=3) ? true : false
        }
      } else { 
        this.seccionesReview[indexFlag].complete = completeLicencia
      }
      //----------
      //Tiene Auto y tarjeta circulacion // estatus - 20
      if (this.current_profile_status>= this.zstatUser.TIENES_AUTO ){

        var myArrAuto = this.profileRecords.filter(item=> item.categoriaId===13)
        var myArrCircula = this.profileRecords.filter(item=> item.categoriaId===54)
        var indexOfNOLIC = this.profileRecords.findIndex( item=> item.catalogo_id === 98)
        if (indexOfNOLIC>=0){
          var ValidAutoCircula = false 
          if (myArrAuto.length==1 && myArrCircula.length<=0) { 
            ValidAutoCircula = true
          } else if (myArrAuto.length==1 && myArrCircula.length==1) { 
            ValidAutoCircula =  (myArrCircula[0].catalogo_id===732 || myArrCircula[0].catalogo_id===731) ? true : false 
          }
          indexFlag = this.seccionesReview.findIndex( item => item.id===10)
          this.seccionesReview[indexFlag].complete = ValidAutoCircula // (myArrAuto.length==1 && myArrCircula.length<=0) ? true : false
          indexFlag = this.seccionesReview.findIndex( item => item.id===11)
          this.seccionesReview[indexFlag].complete = ValidAutoCircula // (myArrAuto.length===1 && myArrCircula.length<=0) ? true : false
        } else {
          indexFlag = this.seccionesReview.findIndex( item => item.id===10)
          this.seccionesReview[indexFlag].complete = (myArrAuto.length==1 ) ? true : false
          indexFlag = this.seccionesReview.findIndex( item => item.id===11)
          this.seccionesReview[indexFlag].complete = (myArrAuto.length===1 && myArrCircula.length===1) ? true : false
        }
      } else { 
        indexFlag = this.seccionesReview.findIndex( item => item.id===10)
        this.seccionesReview[indexFlag].complete = false
        indexFlag = this.seccionesReview.findIndex( item => item.id===11)
        this.seccionesReview[indexFlag].complete = false
      }
      //----------
      //MEDIDAS // estatus - 21
      if (this.current_profile_status>= this.zstatUser.MEDIDAS ){
        //----------
        // Sexo 
        indexFlag = this.seccionesReview.findIndex( item => item.id===12)
        var myArrSexo =  this.profileRecords.filter(item=> item.categoriaId===1) 
        this.seccionesReview[indexFlag].complete = (myArrSexo.length==1) ? true : false
        //----------
        // Estatura
        indexFlag = this.seccionesReview.findIndex( item => item.id===13)
        this.seccionesReview[indexFlag].complete = (this.rvData_Estatura.length>=1) ? true : false 
        //----------
        // Peso
        indexFlag = this.seccionesReview.findIndex( item => item.id===14)
        this.seccionesReview[indexFlag].complete = (this.rvData_Peso.length>=1) ? true : false 
        //----------
        // talla
        indexFlag = this.seccionesReview.findIndex( item => item.id===15)
        this.seccionesReview[indexFlag].complete = (this.rvData_talla.length>=1) ? true : false 
        //----------
      } else {
        var medidasEstatus = [12,13,14,15]
        medidasEstatus.forEach( itemInd => { 
          indexFlag = this.seccionesReview.findIndex( item => item.id===itemInd)
          this.seccionesReview[indexFlag].complete = false
        })
      }
      //----------
      //ESCOLARIDAD // estatus - 22

      if (this.current_profile_status>= this.zstatUser.ESCOLARIDAD ){
        //Último grado de estudios
        indexFlag = this.seccionesReview.findIndex( item => item.id===16)
        this.seccionesReview[indexFlag].complete = (this.rvData_EscuelaGrado.length>=1) ? true : false 
        //----------
        //Título o certificado
        indexFlag = this.seccionesReview.findIndex( item => item.id===17)
        this.seccionesReview[indexFlag].complete = (this.rvData_EscuelaTitulo.length>=1) ? true : false 
        //----------
        //Escuela
        indexFlag = this.seccionesReview.findIndex( item => item.id===18)
        var indexOfEscuelaName = this.profileRecords.findIndex( item=> item.catalogo_id === 748)
        this.seccionesReview[indexFlag].complete = (indexOfEscuelaName>=0) ? true : false 
        //----------
        //Carrera 
        indexFlag = this.seccionesReview.findIndex( item => item.id===19)
        var myArrGrados = this.profileRecords.filter(item=> [144,145,146,147,148,149,733].indexOf(item.categoriaId)>=0)
        if (myArrGrados.length>0){ //Estos grados requieren carrera 
          var indexOfCarreraName = this.profileRecords.findIndex( item=> item.catalogo_id === 749)
          this.seccionesReview[indexFlag].complete = (indexOfCarreraName>=0) ? true : false 
        } else {
          this.seccionesReview[indexFlag].complete = true
        }
        //----------
        //Idiomas 
        indexFlag = this.seccionesReview.findIndex( item => item.id===20)
        this.seccionesReview[indexFlag].complete = (this.rvData_Idiomas.length>=1) ? true : false

      } else {
        var escolaridad_Estatus = [16,17,18,19,20]
        escolaridad_Estatus.forEach( itemInd => { 
          indexFlag = this.seccionesReview.findIndex( item => item.id===itemInd)
          this.seccionesReview[indexFlag].complete = false
        })
      }

      //----------
      //Tiene Experiencia
      //CANALES_EXPERIENCIA // estatus - 23

      if (this.current_profile_status >= this.zstatUser.CANALES_EXPERIENCIA){ 
        var AllExperienceArr = this.profileRecords.filter(item=> item.categoriaId===14 || item.categoriaId===55 || item.categoriaId===17 || item.categoriaId===18)
        var indexOfNOLIC = this.profileRecords.findIndex( item=> item.catalogo_id === 100)
        if (indexOfNOLIC>=0){
          indexFlag = this.seccionesReview.findIndex( item => item.id===21)
          this.seccionesReview[indexFlag].complete = (AllExperienceArr.length===1) ? true : false

          // Tiempo de exp q no tenga registros 
          indexFlag = this.seccionesReview.findIndex( item => item.id===22)
          var arrTPexp = this.profileRecords.filter(item=> item.categoriaId===55) 
          this.seccionesReview[indexFlag].complete = (arrTPexp.length<=0) ? true : false

          // Canales de experiencia generales y especiales q no tenga registros 
          indexFlag = this.seccionesReview.findIndex( item => item.id===23)
          var arrCanales = this.profileRecords.filter(item=> item.categoriaId===17 || item.categoriaId===18)
          this.seccionesReview[indexFlag].complete = (arrCanales.length<=0) ? true : false

        } else {

          indexFlag = this.seccionesReview.findIndex( item => item.id===21)
          this.seccionesReview[indexFlag].complete = (AllExperienceArr.length>=3) ? true : false

          // Tiempo de exp q SI tenga registros 
          indexFlag = this.seccionesReview.findIndex( item => item.id===22)
          var arrTPexp = this.profileRecords.filter(item=> item.categoriaId===55) 
          this.seccionesReview[indexFlag].complete = (arrTPexp.length===1) ? true : false

          // Canales de experiencia generales y especiales q SI tenga registros 
          indexFlag = this.seccionesReview.findIndex( item => item.id===23)
          var arrCanales = this.profileRecords.filter(item=> item.categoriaId===17 || item.categoriaId===18)
          this.seccionesReview[indexFlag].complete = (arrCanales.length>=1) ? true : false

        }
      } else {

        var experience_Estatus = [21,22,23]
        experience_Estatus.forEach( itemInd => { 
          indexFlag = this.seccionesReview.findIndex( item => item.id===itemInd)
          this.seccionesReview[indexFlag].complete = false
        })

      }
      //-----------
      // EXperiencia LABORAL // estatus - 24
      // POR HACER
      var completeLaboral = false 
      indexFlag = this.seccionesReview.findIndex( item => item.id===24)
      if (this.current_profile_status >= this.zstatUser.LABORAL_EXPERIENCIA){ 
        var indexOfExperiencia = this.profileRecords.findIndex( item=> item.catalogo_id === 99) //Si tiene exp 
        if (indexOfExperiencia>=0){
          this.seccionesReview[indexFlag].complete = (this.current_profile._embedded.experienciaLaboral.length>0) ? true : false 
        } else {
          this.seccionesReview[indexFlag].complete = true 
        }
      } else {
        this.seccionesReview[indexFlag].complete = completeLaboral
      }

      //-----------
      // Curriculum // estatus - 25
      indexFlag = this.seccionesReview.findIndex( item => item.id===25)
      this.seccionesReview[indexFlag].complete = (this.current_profile_status>= this.zstatUser.CURRICULUM ) ? ((this.docsDB_Records.length>=1) ? true : false) : false 

      // MEdios sociales 
      indexFlag = this.seccionesReview.findIndex( item => item.id===26)
      var indexOfNOTRABNOS = this.profileRecords.findIndex( item=> item.catalogo_id === 757)
      var indexOfInfonavit = this.profileRecords.findIndex( item=> item.catalogo_id === 965 || item.catalogo_id === 966)

      if (indexOfNOTRABNOS>=0){

        // this.seccionesReview[indexFlag].complete = true
        this.seccionesReview[indexFlag].complete = (indexOfInfonavit>=0) ? true : false 

      } else {
        // SI ha trabajado con nosotros 
        // Verificamos los medios de como se entero
        var arrRedes = this.profileRecords.filter(item=> item.categoriaId===4)
        var arrEdoCivil = this.profileRecords.filter(item=> item.categoriaId===9)
        if(arrRedes.length<=0){
          this.seccionesReview[indexFlag].complete = false
        } else {
          if (arrEdoCivil.length<=0){ 
            this.seccionesReview[indexFlag].complete = false
          } else { 
            this.seccionesReview[indexFlag].complete = (indexOfInfonavit>=0) ? true : false
          }
          //this.seccionesReview[indexFlag].complete = (arrEdoCivil.length<=0) ? false : true
        }
        //this.seccionesReview[indexFlag].complete = (arrRedes.length<=0) ? false : true
      }
      // -----------
      // Terminamos de comparar cada seccion 
      this.$forceUpdate() 

      await this._waitRequestProc(1000)

      var GlobalTEster =  this.seccionesReview.filter( itemSx => itemSx.complete === false  )
      var ToreturnValidationValue = (GlobalTEster.length >0) ? false : true 
      return ToreturnValidationValue

    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }
      this.infoPersona = JSON.parse(JSON.stringify(this.getProfileData))
      //--------------------------
      this.seccionesReview = JSON.parse(JSON.stringify(tplSecciones))
      //--------------------------
      var testImagenName = this.current_profile.imagen 
      if (testImagenName===undefined || testImagenName===null || testImagenName==='') {
        this.userHasExp_Photo = false 
      } else {
        var testFile = '' + this.current_profile._links['file-stream'].href
        if (testFile!==undefined && testFile!==null &&  testFile!==''){ //testImagenName.startsWith('expediente_') === true &&
          this.userHasExp_Photo = true
        } else {
          this.userHasExp_Photo = false 
        }
      }
      //--------------------------
      // Obtenemos los valores de Filtros 
      //--------------------------
      var mcModel = this.getModelRequest('profileFiltro')
      var mcurricModel = this.getModelRequest('profile_Docs')
      var reqRes = { action: [], login: [], profile: [], curric:[] }
      var reqErr = { action: 0, login: 0, profile: 0, curric:0 }

      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, { sort: 'catalogo_id', 'per-page': 'all', expand:'catalogo'}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'filtros', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        this.z_nodataFlag = true 
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))
      //----------------------------
      // Cargamos datos de curriculum
      var myCurricConfig = this.createAxiosConfig ('get', mcurricModel, {
        'per-page': 'all', expand:'catalogo', sort:'-id', catalogo_id:772 
      }, true, null)

      await this.doRequestByPromise(this.$apiV2, 'profDocs', reqRes.curric, myCurricConfig).then((resx) => {
        // console.log ('Action -- Carga de Docs -- GET ejecutado')
        var tmpresponseData = reqRes.curric[reqRes.curric.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.curric += 1
        }
      })
      if (reqErr.curric > 0) { // Si hay error de server, session o algo redireccionamos..!
        this.docsDB_Records = []
      } else { 
        var myDocsData = reqRes.curric[0].data.data
        this.docsDB_Records = JSON.parse(JSON.stringify(myDocsData))
      }
      //----------------------------
      if (profileData.length<=0){
        this.z_nodataFlag = true
      } else {
        this.z_nodataFlag = false
      }
      await this._waitRequestProc(200)

      if (this.current_profile_status>= this.zstatUser.LICENCIA){ // 19
        this.textos_Licencias_InfoCreate()
        await this._waitRequestProc(100)
      }

      if (this.current_profile_status>= this.zstatUser.CANALES_EXPERIENCIA){ // 23 
        this.textos_Experiencia_InfoCreate()
        await this._waitRequestProc(100)
      }

      if (this.current_profile_status>= this.zstatUser.LABORAL_EXPERIENCIA){ // 24 devsp-12 nueva pantalla 
        this.textos_ExperienciaLaboral_InfoCreate()
        await this._waitRequestProc(100)
      }

      //----------------------------
      //REvisamos el Estatus final de Cada seccion 
      var finalResp = null
      await this.estatus_doCHeckFinalData().then(response => { 
        finalResp = response
      })
      this.userProfileComplete = finalResp

      if ( finalResp === true ){
        // SE actualiza el estatus al 100%
        // Actualizar Datos de Estatus // WIP 
        var retUPS = null
        if (this.currProfile_status < this.zstatUser.COMPLETO ){ //< 27 -> 28 
          await this.hpb_updateProfileStatus( this.zstatUser.COMPLETO ).then(respx=>{
            retUPS = respx
          })
        }
        // -----------
      }
      //-----------------------------
      this.$forceUpdate()
      await this._waitRequestProc(100)
      //----------------------------
      this.zinitData = true 
      return true 
    },
    createData_Section ( sectionItem, records ) { 
      //PAra crear secciones genericas 
      var retSrt = ''
      return retSrt 
    },
    createDataDireccion (sectionItem ){ 
      var retSrt = ''
      return retSrt 
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_review -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_review -- created')
    this.zloadingData = true 
    this.zinitData = false 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_review --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_review -- mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)
    var categorias = [63] 
    for (let categx of categorias) { 
      if (this.getHelperCat(categx).dataLoaded===false){ 
        var testCatA = false 
        await this.helpermix_getCatalog (categx, null).then( respx => { 
          this.$cannaDebug('-- psiPage_horario -- categoria ' + categx  + ' finish ')
          testCatA = respx 
        })
      }
      await this._waitRequestProc(100)
    }
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    await this._waitRequestProc(400) 
    var respEstatusData = null 
    await this.estatus_doCHeckFinalData().then( respx => {
      respEstatusData = respx 
    })
    this.$cannaDebug('-- psiPage_review -- estatus check -- ' + respEstatusData)
    await this._waitRequestProc(200)
    this.$forceUpdate()
    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_review -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_review -- updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_review -- activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)
      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      await this._waitRequestProc(400)
      var respEstatusData = null 
      await this.estatus_doCHeckFinalData().then( respx => {
        respEstatusData = respx 
      })
      this.$cannaDebug('-- psiPage_review -- estatus check -- ' + respEstatusData)
      this.$forceUpdate()
      // if (this.$refs.fldx_canalesall){
      //   this.$refs.fldx_canalesall.reset()
      // }
      await this._waitRequestProc(200)
      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_review -- deactivated')
    this.zinitData = false
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_review -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_review -- destroyed')
  }
}
</script>
